define("ember-metrics/services/metrics", ["exports", "@ember/service", "@ember/debug", "@ember/string", "@ember/application"], function (_exports, _service, _debug, _string, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Metrics extends _service.default {
    get options() {
      (false && !({
        id: 'ember-metrics:issue-298',
        url: 'https://github.com/adopted-ember-addons/ember-metrics/issues/298',
        until: '2.0.0'
      }) && (0, _debug.deprecate)('Access to Metrics#options will be removed', {
        id: 'ember-metrics:issue-298',
        url: 'https://github.com/adopted-ember-addons/ember-metrics/issues/298',
        until: '2.0.0'
      }));
      return this._options;
    }

    /**
     * Environment the host application is running in (e.g. development or production).
     */

    /**
     * When the Service is created, activate adapters that were specified in the
     * configuration. This config is injected into the Service as
     * `options`.
     */
    constructor() {
      super(...arguments);
      /**
       * Cached adapters to reduce multiple expensive lookups.
       *
       * @property _adapters
       * @private
       * @type Object
       * @default null
       */
      _defineProperty(this, "_adapters", {});
      /**
       * Contextual information attached to each call to an adapter. Often you'll
       * want to include things like `currentUser.name` with every event or page
       * view  that's tracked. Any properties that you bind to `metrics.context`
       * will be merged into the options for every service call.
       *
       * @property context
       * @type Object
       * @default null
       */
      _defineProperty(this, "context", {});
      /**
       * Indicates whether calls to the service will be forwarded to the adapters.
       * This is determined by investigating the user's doNotTrack settings.
       *
       * Note that the doNotTrack specification is deprecated, and could stop
       * working at any minute. As such should this feature not be detected we
       * presume tracking is permitted.
       *
       * @property enabled
       * @type Boolean
       */
      _defineProperty(this, "enabled", typeof navigator !== 'undefined' && navigator.doNotTrack !== '1');
      _defineProperty(this, "appEnvironment", null);
      const owner = (0, _application.getOwner)(this);
      owner.registerOptionsForType('ember-metrics@metrics-adapter', {
        instantiate: false
      });
      owner.registerOptionsForType('metrics-adapter', {
        instantiate: false
      });
      const config = owner.factoryFor('config:environment').class;
      const {
        metricsAdapters = []
      } = config;
      const {
        environment = 'development'
      } = config;
      this._options = {
        metricsAdapters,
        environment
      };
      this.appEnvironment = environment;
      this.activateAdapters(metricsAdapters);
    }

    /**
     * Instantiates adapters from passed adapter options and caches them for future retrieval.
     *
     * @method activateAdapters
     * @param {Array} adapterOptions
     * @return {Object} instantiated adapters
     */
    activateAdapters(adapterOptions = []) {
      if (!this.enabled) {
        return;
      }
      const adaptersForEnv = this._adaptersForEnv(adapterOptions);
      const activeAdapters = {};
      for (let {
        name,
        config
      } of adaptersForEnv) {
        let adapterClass = this._lookupAdapter(name);
        if (typeof FastBoot === 'undefined' || adapterClass.supportsFastBoot) {
          activeAdapters[name] = this._adapters[name] || this._activateAdapter({
            adapterClass,
            config
          });
        }
      }
      this._adapters = activeAdapters;
      return this._adapters;
    }

    /**
     * Returns all adapterOptions that should be activated in the current application environment.
     * Defaults to all environments if the option is `all` or undefined.
     *
     * @method adaptersForEnv
     * @param {Array} adapterOptions
     * @private
     * @return {Array} - adapter options in the current environment
     */
    _adaptersForEnv(adapterOptions = []) {
      return adapterOptions.filter(({
        environments = ['all']
      }) => {
        return environments.includes('all') || environments.includes(this.appEnvironment);
      });
    }

    /**
     * Looks up the adapter from the container. Prioritizes the consuming app's
     * adapters over the addon's adapters.
     *
     * @method _lookupAdapter
     * @param {String} adapterName
     * @private
     * @return {Adapter} a local adapter or an adapter from the addon
     */
    _lookupAdapter(adapterName) {
      (false && !(adapterName) && (0, _debug.assert)('[ember-metrics] Could not find metrics adapter without a name.', adapterName));
      const availableAdapter = (0, _application.getOwner)(this).lookup(`ember-metrics@metrics-adapter:${(0, _string.dasherize)(adapterName)}`);
      const localAdapter = (0, _application.getOwner)(this).lookup(`metrics-adapter:${(0, _string.dasherize)(adapterName)}`);
      const adapter = localAdapter || availableAdapter;
      (false && !(adapter) && (0, _debug.assert)(`[ember-metrics] Could not find metrics adapter ${adapterName}.`, adapter));
      return adapter;
    }

    /**
     * Instantiates an adapter.
     *
     * @method _activateAdapter
     * @param {Object}
     * @private
     * @return {Adapter}
     */
    _activateAdapter({
      adapterClass,
      config
    }) {
      const decoratedClass = class decoratedClass extends adapterClass {
        constructor(...args) {
          super(...args);
          _defineProperty(this, "identify", this.identify);
          _defineProperty(this, "alias", this.alias);
          _defineProperty(this, "trackEvent", this.trackEvent);
          _defineProperty(this, "trackPage", this.trackPage);
        }
      };
      return new decoratedClass(config, (0, _application.getOwner)(this));
    }
    identify() {
      this.invoke('identify', ...arguments);
    }
    alias() {
      this.invoke('alias', ...arguments);
    }
    trackEvent() {
      this.invoke('trackEvent', ...arguments);
    }
    trackPage() {
      this.invoke('trackPage', ...arguments);
    }

    /**
     * Invokes a method on the passed adapter, or across all activated adapters if not passed.
     *
     * @method invoke
     * @param {String} methodName
     * @param {Rest} args
     * @return {Void}
     */
    invoke(methodName, ...args) {
      if (!this.enabled) {
        return;
      }
      let selectedAdapterNames, options;
      if (args.length > 1) {
        selectedAdapterNames = makeArray(args[0]);
        options = args[1];
      } else {
        selectedAdapterNames = Object.keys(this._adapters);
        options = args[0];
      }
      for (let adapterName of selectedAdapterNames) {
        let adapter = this._adapters[adapterName];
        adapter && adapter[methodName]({
          ...this.context,
          ...options
        });
      }
    }

    /**
     * On teardown, destroy cached adapters together with the Service.
     *
     * @method willDestroy
     * @return {Void}
     */
    willDestroy() {
      Object.values(this._adapters).forEach(adapter => adapter.destroy());
    }
  }
  _exports.default = Metrics;
  function makeArray(maybeArray) {
    return Array.isArray(maybeArray) ? Array.from(maybeArray) : Array(maybeArray);
  }
});